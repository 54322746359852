import React from "react";
import { useInView } from "react-intersection-observer";
import { workExperience } from "../../data/experience";
import { VerticalTimelineElement, VerticalTimeline } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css"
import Frshr from '../../images/FrshrIcon.jpeg';
import Bookee from '../../images/BookeeIcon.jpeg';
import Fyle from '../../images/FyleIcon.png';
import Wws from '../../images/WWSIcon.jpeg';
import './Experience.css'; 

const Experience = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  const iconMap = {
    Frshr,
    Bookee,
    Fyle,
    Wws
  };

  const getIcon = (iconName) => {
    return (
      <img
        src={iconMap[iconName]}
        alt="some"
        className="icon"
      />
    )
  };

  return (
    <section ref={ref} className="experience-container">
      <div className="title-container">
        <p className="subtitle">What I Have Done So Far</p>
        <h2 className="title">Industry Experience</h2>
      </div>
      <VerticalTimeline>
        {workExperience.map((el, index) => {

          return (
            <VerticalTimelineElement
              contentStyle={{ background: 'rgb(29, 24, 54)', color: 'rgb(255, 255, 255)' }}
              key={index}
              dateClassName="date"
              date={el.period}
              icon={getIcon(el.icon)}
            >
              <h3 className="vertical-timeline-element-timeline">
                {el.title}
              </h3>
              <h5 className="vertical-timeline-element-subtitle">
                {el.company}
              </h5>
              <p className="description"></p>
            </VerticalTimelineElement>
          )
        })}

      </VerticalTimeline>
    </section>
  );
};

export default Experience;
