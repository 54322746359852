import React from "react";
import './Project.css'
import countProject from '../../images/count.jpg'
import resumeProject from '../../images/resume.jpg'
import fitProject from '../../images/fit.png'
import { projects } from "../../data/projects";
import { FaGithub } from "react-icons/fa";
import { IoOpenOutline } from "react-icons/io5";

const Projects = () => {

    const previewImage = {
        countProject,
        resumeProject,
        fitProject
    };

    const ProjectCard = ({ project }) => {
        const { title, description, preview, redirections, tech } = project;

        return (
            <div className="cardWrap">
                <div className="detail-wrap">
                    <p className="prj-title">{title}</p>
                    <div className="prj-description">{description}</div>
                    <div className="prj-tech-list">
                        {tech.map((el, index) => (
                            <div key={index} className="prj-tech-item">
                                {el}
                            </div>
                        ))}
                    </div>

                </div>
                <div className="prj-img-wrap">
                    {preview && <img src={previewImage[preview]} alt={title} className="prj-img" />}
                </div>
                <div className="prj-urls">
                    <a href={redirections.githubUrl} target="_blank" rel="noopener noreferrer">
                        <FaGithub />
                    </a>
                    <a href={redirections.projectUrl} target="_blank" rel="noopener noreferrer">
                        <IoOpenOutline />
                    </a>
                </div>
            </div>
        );
    };

    return (
        <section className="project-container">
            <div className="title-container">
                <p className="subtitle">My Work</p>
                <h2 className="title">Project Showcase</h2>
            </div>

            <div className="prj-list">
                {projects.map((project, index) => (
                    <ProjectCard key={index} project={project} />
                ))}
            </div>
        </section>
    )
}

export default Projects