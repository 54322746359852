export const workExperience = [
  {
    period: "Aug 2024 - Present",
    title: "SDE-2",
    company: "Bookee",
    icon: "Bookee",
    description: [""],
  },
  {
    period: "Sep 2022 - July 2024",
    title: "SDE-1",
    company: "Bookee",
    icon: "Bookee",
    description: [""],
  },
  {
    period: "Jul 2022 - Aug 2022",
    title: "Frontend Developer Intern",
    company: "Bookee",
    icon: "Bookee",
    description: [""],
  },
  {
    period: "Nov 2021 - Mar 2022",
    title: "Engineering Intern",
    company: "Fyle",
    icon: "Fyle",
    description: [""],
  },
  {
    period: "Jun 2021 - Oct 2021",
    title: "SDE Intern",
    company: "Frshr Technologies",
    icon: "Frshr",
    description: [""],
  },
  // {
  //   period: "Jun 2020 - Nov 2020",
  //   title: "Frontend Developer Intern",
  //   company: "WishWorks Solutions Pvt Ltd",
  //   icon: "Wws",
  //   description: [""],
  // },
];