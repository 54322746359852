import React from "react";
import { contactURL } from "../../data/contacts";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import { MdOutlineMailOutline } from "react-icons/md";
import './Contact.css'

const Contact = () => {
    return (
        <section className="contact-section">
            <div className="contact-text">
                Feel free to reach out to me on
            </div>

            <div className="contact-url">
                <a href={contactURL.EMAIL} target="_blank" rel="noopener noreferrer" aria-label="Email">
                    <MdOutlineMailOutline />
                </a>
                <a href={contactURL.GITHUB} target="_blank" rel="noopener noreferrer" aria-label="GitHub">
                    <FaGithub />
                </a>
                <a href={contactURL.LINKEDIN} target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                    <FaLinkedinIn />
                </a>
            </div>
        </section>
    )
}

export default Contact;