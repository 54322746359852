import React from "react";
import './About.css'
import profile from '../../images/profile.jpeg'
import { MdOutlineDownloadForOffline } from "react-icons/md";

const About = () => {
    return (
        <>
            <section className="master-container">
                <div className="master-text-wrap">
                    <h2>Hi, I'm
                        <span> Siddharth</span>
                    </h2>
                    <p>I'm a frontend engineer</p>
                </div>
                <div className="img-wrap">
                    <img src={profile} alt="profile" />
                </div>
            </section>

            <section className="about-container">
                <div className="title-container">
                    <p className="subtitle">Introduction</p>
                    <h2 className="title">About Me</h2>
                </div>

                <div className="content-wrap">
                    <div className="about-wrap">
                        <p>
                            Hey, I'm a frontend enthusiast with a knack for making websites shine. For the past couple of years, I've been diving into the world of Angular and React, bringing designs to life with a mix of creativity and coding chops.
                            Prior to that, I delved into various internships, eagerly absorbing insights and refining my skills. Startups are where I thrive—I love the energy of building something from the ground up. Whether it's a sleek interface or a seamless user journey, I'm all about making web experiences unforgettable.
                            Let's collaborate and turn ideas into reality!
                        </p>
                    </div>
                    <div className="resume-wrap">
                        <a href="https://drive.google.com/file/d/1UhN5jQ1zi3oc6c1ecxgvoC0k7ltFjFtP/view"  target="_blank">
                            Resume
                            <MdOutlineDownloadForOffline />
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;