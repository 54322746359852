export const projects = [
  {
    title: "Resume builder",
    description: "Creates a basic resume in just four easy steps. You can also preview your resume and download it at last step.",
    tech: ['ReactJs'],
    preview: 'resumeProject',
    redirections: {
      githubUrl: "https://github.com/siddharth0-0/ResumeBuilder",
      projectUrl: "https://idyllic-vacherin-234832.netlify.app",
    },
  },
  {
    title: "Countdown application",
    description:
      "A coutdown app which shows countdown to some of the major world events. You can also create and view our own coutdowns with the help of 6-digit unique key.",
    tech: ["Angular", "Firebase"],
    preview: 'countProject',
    redirections: {
      githubUrl: "https://github.com/siddharth0-0/countdown",
      projectUrl: "https://tender-mccarthy-ba980c.netlify.app",
    },
  },
  {
    title: "Fitguide",
    description:
      "A application which guides you through fitness activities and diet. The activities can be accessed in image and video format.",
    tech: ["Angular"],
    preview: 'fitProject',
    redirections: {
      githubUrl: "https://github.com/siddharth0-0/fitguide",
      projectUrl: "https://pensive-leavitt-0fd945.netlify.app",
    },
  }
];
